<template>
    <main class="d-flex flex-column justify-content-center align-items-center hv-100 ml-3 mr-3">
        <div class="box-login" :class="platform">
            <div class="m-3 text-center">
                <div class="row align-items-center">
                    <div class="col-auto p-0">
                        <a class="text-theme display-5" href="javascript:;" @click="historyBack">
                            <i class="fas fa-long-arrow-left"></i>    
                        </a>
                    </div>
                    <div class="col">
                        <template v-if="modApp">
                            <div class="display-3 text-theme">
                                <i class="fas fa-user-shield"></i>
                            </div>
                        </template>
                        <template v-else>
                            <a href="/">
                                <img :src="logo" width="250" alt="logo">
                            </a>
                        </template>
                    </div>
                </div>
            </div>
            <template v-if="step == 0">
                <h5 class="text-white text-uppercase border-bottom pb-2 mb-3">Login</h5>
                <div class="alert text-center" role="alert" :class="alert.type == 0 ? 'alert-danger' : 'alert-success'" v-show="alert.message">
                    {{alert.message}}
                </div>
                <form @submit.prevent.stop="login('login')">
                    <div class="form-group">
                        <label class="text-light">Usuário</label>
                        <input type="text" class="form-control" v-model="username" autocomplete="off" spellcheck="false" required>
                    </div>
                    <div class="form-group">
                        <label class="text-light">Senha</label>
                        <input type="password" class="form-control" v-model="password" autocomplete="off" spellcheck="false" required>
                    </div>
                    <div class="row">
                        <div class="col" :class="{'pr-0': platform == 'desktop'}">
                            <button class="btn btn-success btn-block mb-3" type="submit" :disabled="username == '' || password == ''">Fazer login</button>
                        </div>
                        <div class="w-100" v-if="platform == 'mobile'"></div>
                        <div :class="platform == 'desktop' ? 'col-auto pr-0' : 'col pr-0'">
                            <a href="javascript:;" class="btn btn-block" :class="platform == 'desktop' ? 'btn-primary' : 'btn-outline-warning'" @click="step = 2">Validar PIN</a>
                        </div>
                        <div :class="platform == 'desktop' ? 'col-auto' : 'col'">
                            <a href="javascript:;" class="btn btn-outline-warning btn-block" @click="step = 1">Recuperar conta</a>
                        </div>
                    </div>
                </form>
            </template>
            <template v-else-if="step == 1">
                <h5 class="text-white text-uppercase border-bottom pb-2 mb-3">Recuperar conta</h5>
                <div class="alert text-center" role="alert" :class="alert.type == 0 ? 'alert-danger' : 'alert-success'" v-show="alert.message">
                    {{alert.message}}
                    <div class="w-100" v-show="alert.type == 1">
                        <hr />
                        <span>Verifique seu e-mail e faça o login com a nova senha!</span>
                    </div>
                </div>
                <form @submit.prevent.stop="recoverAccount">
                    <div class="form-group">
                        <label class="text-light">CPF da conta</label>
                        <the-mask class="form-control" v-model="cpf" :mask="['###.###.###-##']" autocomplete="off" spellcheck="false" required />
                    </div>
                    <div class="row">
                        <div class="col pr-0">
                            <button class="btn btn-success btn-block mb-3" type="submit" :disabled="cpf == ''">Recuperar</button>
                        </div>
                        <div class="col-auto">
                            <a href="javascript:;" class="btn btn-outline-warning btn-block" @click="step = 0">Fazer login</a>
                        </div>
                    </div>  
                </form>
            </template>
            <template v-else>
                <h5 class="text-white text-uppercase border-bottom pb-2 mb-3">Autenticação</h5>
                <div class="alert text-center" role="alert" :class="alert.type == 0 ? 'alert-danger' : 'alert-success'" v-show="alert.message">
                    {{alert.message}}
                </div>
                <form @submit.prevent.stop="login('pin')">
                    <div class="form-group">
                        <label class="text-light">Informe o PIN</label>
                        <input type="text" class="form-control" v-model="pin" maxlength="10" autocomplete="off" spellcheck="false" required>
                    </div>
                    <div class="row">
                        <div class="col pr-0">
                            <button class="btn btn-success btn-block mb-3" type="submit" :disabled="pin == ''">Validar PIN</button>
                        </div>
                        <div class="col-auto">
                            <a href="javascript:;" class="btn btn-outline-warning btn-block" @click="step = 0">Fazer login</a>
                        </div>
                    </div>
                </form>
            </template>
        </div>
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import {TheMask} from 'vue-the-mask'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            pin: '',
            step: 0,
            cpf: '',
            alert: {
                type: 0,
                message: ''
            },
            callback: '',
            username: '',
            password: '',
            loading: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        TheMask,
        Loading
    },
    methods: {
        login(action) {
            
            const self = this;

            if (action == 'login' && self.username == '') {
                self.alert.type = 0;
                self.alert.message = 'Informe o nome de usuário!';
            } else if (action == 'login' && self.password == '') {
                self.alert.type = 0;
                self.alert.message = 'Informe sua senha!';
            } else if (action == 'pin' && self.pin == '') {
                self.alert.type = 0;
                self.alert.message = 'Informe o PIN!';
            } else {

                self.loading = true;
                self.alert.message = '';

                api.post('login', {
                    pin: self.pin,
                    action: action,
                    username: self.username,
                    password: self.password
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.startSession(response.data);
                        break;
                        default:
                            self.alert.type = 0;
                            self.alert.message = response.data.message;
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.alert.type = 0;
                        self.alert.message = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.alert.type = 0;
                        self.alert.message = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.loading = false;
                });
            }
        },
        recoverAccount() {

            const self = this;

            if (self.cpf == '') {
                self.alert.type = 0;
                self.alert.message = 'Informe o CPF da conta!';
            } else {

                self.loading = true;
                self.alert.message = '';

                api.post('retrieve-account', {
                    cpf: self.cpf,
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.cpf = '';
                            self.alert.type = 1;
                            self.alert.message = response.data.message;
                        break;
                        default:
                            self.alert.type = 0;
                            self.alert.message = response.data.message;
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.alert.type = 0;
                        self.alert.message = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.alert.type = 0;
                        self.alert.message = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.loading = false;
                });
            }
        },
        startSession(data) {
            if (data.action == 'login_auth') {
                this.setItem('x-token', data.token).then(() => {
                    this.setItem('auth', JSON.stringify(data.user)).then(() => {
                        this.setItem('firebase:pipe', data.user.pipe).then(() => {
                            window.location.href = this.callback ? `/${this.callback}` : '/';
                        });
                    });
                });
            } else {
                this.setItem('pin-auth', data.ref_id).then(() => {
                    window.location.href = this.callback ? `/${this.callback}` : '/';
                });
            }
        },
        setItem(key, value) {
            return Promise.resolve().then(() => {
                window.localStorage.setItem(key, value);
            });
        },
        initStep() {
            try {
                const step = this.$route.query.step;
                if (step == 0 || step == 1 || step == 2) {
                    this.step = step;
                } else {
                    this.step = 0;
                }
            } catch(e) {
                this.step = 0;
            }
        },
        initCallback() {
            try {
                this.callback = this.$route.query.callback;
            } catch(e) {
                this.callback = '';
            }            
        },
        historyBack() {
            if (document.referrer) {
                window.history.back();
            } else {
                window.location.href = '/';
            }
        }
    },
    watch: {
        step() {
            this.pin = '';
            this.cpf = '';
            this.username = '';
            this.password = '';
            this.alert.type = 0;
            this.alert.message = '';
        },
        pin(value) {
            return this.pin = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        },
        username(value) {
            return this.username = value.toLowerCase().replace(/[^a-z0-9.]/g, '');
        },
        password(value) {
            return this.password = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        }
    },
    mounted() {
        try {
            const account = window.sessionStorage.getItem('account');
            const {user} = JSON.parse(account);
            if (user) this.username = user;
        } catch(e) {
            this.username = '';
        }
    },
    created() {
        this.initStep();
        this.initCallback();
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.h-100vh {
    height: 100%;
}
.box-login {
    margin: 0 auto;
}
.box-login.desktop {
    width: 500px;
}
.box-login.mobile {
    width: 100%;
}
</style>